import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Link} from "react-router-dom"
//i18n
import { withNamespaces } from 'react-i18next';
// Redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from "../../../store/auth/login/actions"

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';

const ProfileMenu = (props) => {

    const [menu, setMenu] = useState(false);
    const dispatch = useDispatch()
    const user = useSelector((state) => state.User) ;
    const {usuario} = user;
    const logOut = (lock = false) =>{
        dispatch(logoutUser(props.history, lock))
    }  
  return (
    <React.Fragment>
        <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block" >
            <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                <img className="rounded-circle header-profile-user" src={usuario.attributes['custom:foto'] || user1} alt="Header Avatar" />
                <span className="d-none d-xl-inline-block ml-2 mr-1">{ usuario ? ( usuario.attributes["custom:apelido"] || usuario.attributes.email || ":)BiZ") : ":)BiZ"}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem tag={Link}  to="/meu-perfil"> <i className="bx bx-user font-size-16 align-middle mr-1"></i>{props.t('Meu perfil')}</DropdownItem>
                <DropdownItem onClick={()=>logOut(true)}><i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{props.t('Travar tela')}</DropdownItem>
                <div className="dropdown-divider"></div>
                <div onClick={()=>logOut(false)} className="dropdown-item">
                    <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                    <span>{props.t('Sair')}</span>
                </div>
            </DropdownMenu>
        </Dropdown>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
    const { error,success } = state.Profile;
    return { error,success };
}

export default withRouter(connect(mapStatetoProps, { })(withNamespaces()(ProfileMenu)));