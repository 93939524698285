import React, { useEffect, useState } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { setUserAction } from "../../store/auth/login/actions";
import { useDispatch } from "react-redux";
import  gerarToken  from "../../helpers/gerarToken";
const Authmiddleware = ({ component: Component, layout: Layout, path }) => {
  const [userLogIn, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const authUser = async () => {
    let erro = false;
    let data = await Auth.currentAuthenticatedUser()
      .then((user) => user)
      .catch((err) => false);

    const tokenNOL = await gerarToken(data?.attributes?.email);

    setUser(data);
    dispatch(setUserAction({ ...data, tokenNOL }));
    setLoading(false);
  };

  useEffect(() => {
    authUser();
  }, []);

  if (loading) return null;
  return (
    <Route
      path={path}
      render={(props) => {
        if (!userLogIn) {
          return (
            <Redirect
              {...props}
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default withRouter(Authmiddleware);
