import React from "react";
import { Redirect } from "react-router-dom";

// autenticacao related pages
import Login from "../pages/autenticacao/Login";
import AlterPassword from "../pages/autenticacao/alterPassword";
import Logout from "../pages/autenticacao/Logout";
import ForgetPwd from "../pages/autenticacao/ForgetPassword";
import lockedScreen from "../pages/autenticacao/lockedScreen";
import cliente from "../cliente";
import { rotas } from "../cliente/rotas";

const userRoutes = rotas[cliente.id];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/locked", component: lockedScreen },
	{ path: "/alter-password", component: AlterPassword },
	{ path: "/forgot-password", component: ForgetPwd },
];

export { userRoutes, authRoutes };
