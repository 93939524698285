import React,{useState} from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import {  showRightSidebarAction,toggleLeftmenu } from "../../store/actions";
// reactstrap

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { withNamespaces } from 'react-i18next';
import cliente from "../../cliente"


const Header = (props) => {

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
      return (
        <React.Fragment>
        <header id="page-topbar" style={props.position ? { position: "initial"} : {} }>
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo">
                    <img src={cliente.logo} alt="" height="35" />
                  </span>
                </Link>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => { props.toggleLeftmenu(!props.leftMenu); }}
                data-target="#topnav-menu-content">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>

            <div className="d-flex">
              <LanguageDropdown />
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  onClick={() => {toggleFullscreen()} }
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-expand"></i>
                </button>
              </div>
              <NotificationDropdown />
              <ProfileMenu />

            </div>
          </div>
        </header>
      </React.Fragment>
      );
    }

const mapStatetoProps = state => {
  const { layoutType,showRightSidebar, leftMenu } = state.Layout;
  return { layoutType,showRightSidebar,leftMenu };
};

export default connect(mapStatetoProps, { showRightSidebarAction,toggleLeftmenu })(withNamespaces()(Header));


