import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_USER_REQUESTED,
  CLEAN_API_MESSAGE,
} from "./actionTypes";
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  setUserSucess,
} from "./actions";

//Include Both Helper File with needed methods
import * as cognito from "../../../helpers/firebase_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(cognito.loginUser, user.email, user.password);
    yield put(setUserSucess(response));
    if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
      history.push("/alter-password");
      return;
    }
    const result = yield call(cognito.getCurrentUser);

    history.push("/dashboard");
  } catch (error) {
    let err = error;
    console.log(error);
    if (err === "User does not exist.") {
      err = "Usuário não existe";
    } else if (err === "Incorrect username or password.") {
      err = "Senha ou email incorreto";
    } else if (
      err ===
      "Temporary password has expired and must be reset by an administrator."
    ) {
      err = "A senha expirou, solicite para um administrador resetar sua senha";
    }
    yield put(apiError(err));
  }
}

function* logoutUser({ payload: { history, lock } }) {
  try {
    const response = yield call(cognito.logout);
    yield put(logoutUserSuccess(response));
    lock ? history.push("/locked") : history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* clearMsg(action) {
  yield put(apiError(""));
}

function* setUser({ payload }) {
  yield put(setUserSucess(payload));
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export function* watchUserSetUser() {
  yield takeEvery(SET_USER_REQUESTED, setUser);
}

export function* watchApiMsgErroClean() {
  yield takeEvery(CLEAN_API_MESSAGE, clearMsg);
}

function* authSaga() {
  yield all([
    fork(watchUserLogin),
    fork(watchUserLogout),
    fork(watchUserSetUser),
    fork(watchApiMsgErroClean),
  ]);
}

export default authSaga;
