import React from 'react';

import { Row, Col, CardBody, Card, Alert, Container, Label } from "reactstrap";

import { useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

import { loginUser } from '../../store/actions';

import zib from "../../assets/images/profile-img.png";
import LogoCliente from './partes/logoCliente';
import { useDispatch } from "react-redux"

import FooterMsg from "./footerMsg"
import HeaderAuth from './partes/headerAutenticacao';
import FooterLogin from "./partes/footer"
import cliente from '../../cliente';
const Locked = (props) => {
    const dispatch = useDispatch()
    const { error, loading } = useSelector((state) => state.Login);
    const user = useSelector((state) => state.User);

    if (!user.usuario) {
        props.history.push("/login");
        return null
    }
    const { attributes } = user.usuario
    function enviarDados(event, values) {
        values.email = attributes.email
        dispatch(loginUser(values, props.history));

    }
    return (
        <React.Fragment>
            <div className="account-pages">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <HeaderAuth titulo="Seja bem-vindo!" subtitulo="Entre no sistema com as suas informações de acesso" imagem={zib} />
                                <CardBody className="pt-0">
                                    <LogoCliente />
                                    <div className="p-2">

                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => { enviarDados(e, v) }}>
                                            {error && error ? <Alert color="danger">{error}</Alert> : null}
                                            <div className="user-thumb text-center mb-4">
                                                <img src={attributes["custom:foto"] || cliente.logoLogIn} className="rounded-circle img-thumbnail avatar-md" alt="thumbnail" />
                                                <h5 className="font-size-15 mt-3">{attributes["custom:apelido"] || attributes.email}</h5>
                                            </div>
                                            <AvGroup>
                                                <Label for="senha">senha</Label>
                                                <AvInput name="password" label="Senha" id="senha" errorMessage="Campo obrigatório" type="password" required placeholder="Senha" />
                                            </AvGroup>
                                            <div className="mt-3">
                                                <button disabled={loading} className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                                                    {
                                                        loading ?
                                                            <>
                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>Carregando
                                                            </>
                                                            :
                                                            "Entrar"
                                                    }
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                Não e você? <Link to="/login" >logar.</Link>
                            </div>
                            <FooterMsg />
                        </Col>
                    </Row>
                </Container>
            </div>

            <FooterLogin />
        </React.Fragment>
    );
}

export default withRouter(Locked);

