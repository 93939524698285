import React from 'react';

import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import {useSelector} from "react-redux"
 
// availity-reactstrap-validation
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';

// actions
import { loginUser,apiError } from '../../store/actions';

import {Auth} from "aws-amplify"
// import images
import profile from "../../assets/images/profile-img.png";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

import FooterMsg from "./footerMsg"
import HeaderAuth from './partes/headerAutenticacao'
import LogoCliente from './partes/logoCliente';
import FooterLogin from "./partes/footer"

const AlterPassword = (props) => {
    const user = useSelector(state => state.Login.user)
    const [loading, setLoafing] = React.useState(false)
    const passwordChanced = (data) => {
        toastr.success("Sua senha foi alterada", "Senha alterada")
        props.history.push('/login');
    }
    const passwordChancedFailed = (e) =>{
        console.log(e)
        toastr.error("Erro ao alterar senha")
    }
    async function changePassword(event, values) {
        setLoafing(true)
        console.log(event, values)
         
        if(values.newPassword.length < 6){
                setLoafing(false)
                toastr.error("Senha deve conter mais de seis digitos")
                return
        }

        await Auth.completeNewPassword(user, values.newPassword)
        .then( data => passwordChanced(data) )
        .catch(err => passwordChancedFailed(err))
        setLoafing(false)
    }
       
    return (
        <React.Fragment>
                <div className="account-pages">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <HeaderAuth titulo="Trocar senha!" subtitulo="Antes de prosseguir altere sua senha." imagem={profile} />
                                    <CardBody className="pt-0">
                                        <LogoCliente />
                                        <div className="p-2">
                                            <AvForm className="form-horizontal" onValidSubmit={(e,v) => { changePassword(e,v) }}>
                                                <AvGroup className="mb-3">
                                                    <Label for="example">nova senha</Label>
                                                    <AvInput name="newPassword" label="*******"  errorMessage="Campo obrigatório" type="password" required placeholder="Nova senha" />
                                                </AvGroup>
                                                <div className="mt-3">
                                                    <button disabled={loading} className="btn btn-primary btn-block waves-effect waves-light" type="submit">{loading ? "Carregando":"Trocar senha"}</button>
                                                </div>

                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                                <FooterMsg />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <FooterLogin />
            </React.Fragment>
          );
        }

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginUser,apiError })(AlterPassword));

